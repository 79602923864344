/*
 * Copyright © 2020-2022 Ocean Galaxy Inc. All Rights Reserved.
 * @Description:
 * @LastEditors: garry彭
 * @LastEditTime: 2022-10-10 15:32:20
 */
export default function getUA() {
  let ua = navigator.userAgent.toLowerCase()
  let uaObj = {}

  let saEnv = 'others' //上报神策的属性

  uaObj.isFastSouGou = ua.match(/metasr/) && ua.match(/chrome/)
  uaObj.isMac =
    navigator.platform == 'Mac68K' ||
    navigator.platform == 'MacPPC' ||
    navigator.platform == 'Macintosh' ||
    navigator.platform == 'MacIntel'
  uaObj.isWin = navigator.platform == 'Win32' || navigator.platform == 'Windows'
  uaObj.isMobile = /iPhone|iPad|iPod|Android/i.test(ua)
  uaObj.isAndroid = /Android/i.test(ua)
  uaObj.isIOS = /iPhone|iPad|iPod|Macintosh|Mac OS/i.test(ua)
  uaObj.isWeiXin = /micromessenger/.test(ua)
  uaObj.isDingTalk = /dingtalk/.test(ua)
  uaObj.isQQ = /qq/.test(ua)
  uaObj.isTodayCamera = /todaycamera/.test(ua)

  if (uaObj.isWeiXin) {
    saEnv = 'wechat'
  } else if (uaObj.isDingTalk) {
    saEnv = 'DingTalk'
  } else if (uaObj.isQQ) {
    saEnv = 'QQ'
  }

  uaObj.saEnv = saEnv

  if (uaObj.isWeiXin) {
    uaObj.weiXinVersion = ua
      .split(' ')
      .find((item) => item.startsWith('micromessenger'))
      .split('/')[1]
      .split('(')[0]
  }

  return uaObj
}

/*
 * Copyright © 2020-2023 Ocean Galaxy Inc. All Rights Reserved.
 * @Description:
 * @LastEditors: garry彭
 * @LastEditTime: 2023-02-14 16:18:07
 */
import { createApp } from 'vue'
import 'lib-flexible'
import App from './App.vue'

const app = createApp(App)

app.mount('#app')

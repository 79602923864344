<!--
 * Copyright © 2020-2024 Ocean Galaxy Inc. All Rights Reserved.
 * @Description: 
 * @LastEditors: caoran
 * @LastEditTime: 2024-09-12 16:45:47
-->
<template>
  <div class="container" @click="handleCloseSelector">
    <div class="header">
      <div class="logo-wrapper">
        <img src="./assets/logo.png" class="logo" />
        <span class="name">Timemark</span>
      </div>

      <div
        class="selector-wrapper"
        @click="(e) => e.stopPropagation()"
        :class="{
          open: openDropdown
        }"
      >
        <div class="selector" @click="handleClickSelector">
          {{ local.toUpperCase() }}
          <img src="./assets/down.png" class="down" />
        </div>

        <div class="dropdown" @click="handleSelectLang">
          <div class="item" value="EN">English</div>
          <div class="item" value="ID">Indonesia</div>
          <div class="item" value="MS">Melayu</div>
          <div class="item" value="VI">Tiếng Việt</div>
        </div>
      </div>
    </div>
    <div class="content" ref="scrollElementRef">
      <div class="slogan-wrapper">
        <div
          v-for="(item, index) in languages.i_timemark_slogan.split('\n')"
          :class="{
            highlight: item === languages.i_timemark_slogan_highlight
          }"
          :key="index"
        >
          {{ item }}
        </div>
      </div>
      <div class="about">{{ languages.i_timemark_slogan_authentic }}</div>
      <div class="download-btn-wrapper">
        <a
          :href="appStoreURL"
          :title="appStoreURL"
          @click="handleAppleDownload('downloadUp')"
        >
          <div class="download-btn download-btn-left">
            <img src="./assets/ios.png" class="sys-icon" />
            App Store
          </div>
        </a>
        <a
          :href="apkURL"
          :title="apkURL"
          @click="handleApkDownload('downloadUp')"
        >
          <div class="download-btn">
            <img src="./assets/android.png" class="sys-icon" />
            Google Play
          </div>
        </a>
      </div>
      <div class="compare-wrapper">
        <div></div>
        <div class="logo-container">
          <div class="logo timemark">
            <img src="./assets/like.png" class="like" />
          </div>
        </div>
        <div class="logo-container">
          <img src="./assets/timeStampCamera.png" class="logo" />
        </div>
        <div class="logo-container">
          <img src="./assets/gpsMapCamera.png" class="logo" />
        </div>

        <template v-for="(item, index) in features" :key="index">
          <div
            class="feature-name"
            :class="{
              odd: index % 2 === 1
            }"
          >
            {{ languages[item] }}
          </div>
          <div
            class="res-container"
            :class="{
              odd: index % 2 === 1
            }"
          >
            <img src="./assets/check.png" class="check" />
          </div>
          <div
            class="res-container"
            :class="{
              odd: index % 2 === 1
            }"
          >
            <img src="./assets/cross.png" class="cross" />
          </div>
          <div
            class="res-container"
            :class="{
              odd: index % 2 === 1
            }"
          >
            <img src="./assets/cross.png" class="cross" />
          </div>
        </template>

        <div class="feature-name">······</div>
      </div>

      <div class="link-wrapper">
        <div class="learning">{{ languages.i_h5_contact }}</div>
        <div class="apps">
          <img
            v-for="(item, index) in apps"
            :key="index"
            :src="item.img"
            class="app-icon"
            @click="handleOpenApp(item)"
          />
        </div>
      </div>

      <div class="download-btn-1" @click="handleDownload('downloadDown')">
        <img src="./assets/ios.png" class="sys-icon" />
        <img src="./assets/android.png" class="sys-icon android" />
        {{ languages.i_download_now }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue'
import lang from './lang.json'
import getUA from '../../../utils/getUA'
import whatsapp from './assets/whatsapp.png'
import zalo from './assets/zalo.png'
import messenger from './assets/messenger.png'
import mail from './assets/mail.png'
import sa from '../../../utils/saUtilsGlobal'

const appStoreURL =
  'https://apps.apple.com/app/apple-store/id6446071834?pt=126093046&ct=officialsite&mt=8'
// 'https://apps.apple.com/app/apple-store/id6446071834?pt=126093046&ct=timemark_official_h5_introduce&mt=8'
const apkURL =
  'https://play.google.com/store/apps/details?id=com.oceangalaxy.camera.new&referrer=utm_source%3Dofficialsite'
// 'https://play.google.com/store/apps/details?id=com.oceangalaxy.camera.new&referrer=utm_source%3Dtimemark_official_h5_introduce'

const UA = getUA()

const defaultLocal = navigator.language.split('-')[0]

const local = ref(defaultLocal)

const scrollElementRef = ref(null)

const languages = new Proxy(lang, {
  get: function (target, property) {
    if (target[property]) {
      return target[property][local.value] || target[property].en
    }

    return ''
  }
})

/**
 * 动态添加meta标签
 * <meta name="description" content="">
 */
const descMeta = document.createElement('meta')
descMeta.setAttribute('name', 'description')
descMeta.setAttribute(
  'content',
  languages.i_timemark_slogan.replace(/\n/g, ' ')
)
document.querySelector('head').appendChild(descMeta)

const handleSaTrack = (actionType, data = {}) => {
  try {
    const params = {
      actionType,
      language: defaultLocal,
      chooseLanguage: local.value,
      ...data
    }

    sa.track('H5_get_action_page_share_app_link', params)

    // eslint-disable-next-line no-undef
    gtag('event', 'H5_get_action_page_share_app_link', params)
  } catch (error) {
    console.log('error', error)
  }
}

const features = [
  'i_h5_anti_fake_time',
  'i_h5_anti_fake_gps',
  'i_h5_company_logo',
  'i_h5_ad_free',
  'i_h5_industry_templates'
]

const apps = [
  {
    img: whatsapp,
    url: 'https://chat.whatsapp.com/L1jLkNjBEy1AiYvTe3CSxV',
    name: 'whatsapp'
  },
  {
    img: zalo,
    url: 'https://zalo.me/g/aqadto479',
    name: 'zalo'
  },
  {
    img: messenger,
    url: 'https://m.me/timemarkcamera',
    name: 'messenger'
  },
  {
    img: mail,
    url: 'mailto:timemarkofficial@gmail.com',
    name: 'mail'
  }
]

const openDropdown = ref(false)

const handleClickSelector = () => {
  openDropdown.value = !openDropdown.value
}

const handleCloseSelector = () => {
  openDropdown.value = false
}

const handleSelectLang = (e) => {
  if (e.target.className === 'item') {
    local.value = e.target.getAttribute('value').toLowerCase()
    openDropdown.value = false
    handleSaTrack('changeLanguage')
  }
}

const handleAppleDownload = (actionType) => {
  handleSaTrack(actionType)
  window.removeEventListener('beforeunload', handleBeforeUnload)
  window.location.href = appStoreURL
  return false
}
const handleApkDownload = (actionType) => {
  handleSaTrack(actionType)
  window.removeEventListener('beforeunload', handleBeforeUnload)
  window.location.href = apkURL
  return false
}
const handleDownload = (actionType) => {
  handleSaTrack(actionType)
  window.removeEventListener('beforeunload', handleBeforeUnload)
  window.location.href = UA.isIOS ? appStoreURL : apkURL
}

const handleOpenApp = (item) => {
  handleSaTrack('connect', { channel: item.name })

  window.location.href = item.url
}

const pageInTime = ref(0)

onMounted(() => {
  pageInTime.value = new Date().getTime()
  handleSaTrack('enter')
  window.addEventListener('beforeunload', handleBeforeUnload)
})
// 在组件卸载前移除事件监听器
onBeforeUnmount(() => {
  window.removeEventListener('beforeunload', handleBeforeUnload)
  const pageHeight = scrollElementRef.value.scrollHeight
  const scrollHeight =
    scrollElementRef.value.scrollTop + scrollElementRef.value.offsetHeight
  const ratio = (scrollHeight / pageHeight).toFixed(1)
  handleSaTrack('back', {
    processTime: parseInt((new Date().getTime() - pageInTime.value) / 1000),
    ratio: ratio
  })
})
const handleBeforeUnload = (event) => {
  // 执行一些清理操作或保存数据
  event.preventDefault()
  event.returnValue = ''
  const pageHeight = scrollElementRef.value.scrollHeight
  const scrollHeight =
    scrollElementRef.value.scrollTop + scrollElementRef.value.offsetHeight
  const ratio = (scrollHeight / pageHeight).toFixed(1)
  handleSaTrack('back', {
    processTime: parseInt((new Date().getTime() - pageInTime.value) / 1000),
    ratio: ratio
  })
}
</script>

<style>
body,
h1,
h2,
h3,
h4,
p {
  padding: 0;
  margin: 0;
}

* {
  box-sizing: border-box;
  -webkit-user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

body {
  font-family: PingFangSC-Regular, Roboto, Helvetica Neue, Helvetica, Tahoma,
    Arial, PingFang SC-Light, Microsoft YaHei;
  touch-action: manipulation;
  overflow-x: hidden;
}

@font-face {
  font-family: Montserrat;
  src: url(./assets/fonts/Montserrat-ExtraBold.ttf);
  font-display: fallback;
}
</style>

<style lang="scss" scoped>
.container {
  max-width: 540px;
  height: 100vh;
  margin: 0 auto;
  background: #fff;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  // -webkit-overflow-scrolling: touch;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  font-family: 'SF Pro Text', 'SF Pro Icons', 'Helvetica Neue', 'Helvetica',
    'Arial', sans-serif;

  .header {
    height: 60px;
    display: flex;
    align-items: center;
    padding: 0 16px;
    justify-content: space-between;

    .logo-wrapper {
      display: flex;
      align-items: center;

      .logo {
        width: 34px;
        height: 34px;
        margin-right: 8px;
      }

      .name {
        font-size: 18px;
        font-weight: 590;
        color: #000;
      }
    }

    .selector-wrapper {
      position: relative;

      &.open {
        .dropdown {
          max-height: 240px;
          box-shadow: 0px 2px 8px 0px #00000033;
        }

        .down {
          transform: rotate(180deg);
        }
      }

      .selector {
        height: 24px;
        width: 46px;
        display: flex;
        align-items: center;
        padding: 0 8px;
        border: solid 1px #d9d9d9;
        border-radius: 2px;
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        color: #555;
        justify-content: space-between;

        .down {
          width: 6px;
          height: 3px;
          margin-left: 5px;
          transition: transform 0.2s ease;
        }
      }

      .dropdown {
        position: absolute;
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.2s ease;
        background: #fff;
        padding: 8px;
        right: 0;

        .item {
          width: 108px;
          height: 48px;
          display: flex;
          align-items: center;
          padding-left: 16px;
          font-family: PingFang SC;
          font-size: 16px;
          font-weight: 400;
          color: #222;
        }
      }
    }
  }

  .content {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;

    .slogan-wrapper {
      padding: 0 20px;
      margin-top: 20px;
      font-family: Montserrat;
      font-size: 40px;
      line-height: 49px;
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #222;
      text-align: center;

      .highlight {
        background: url('./assets/text-bg.png') no-repeat;
        background-size: cover;
        -webkit-background-clip: text;
        color: transparent;
      }
    }

    .about {
      font-size: 18px;
      font-weight: 400;
      line-height: 21px;
      text-align: center;
      color: #808080;
      margin-top: 10px;
      width: 296px;
    }

    .download-btn-wrapper {
      margin-top: 30px;
      display: flex;
      justify-content: center;
      width: 100%;
      a {
        text-decoration: none;
      }
      .download-btn {
        width: 143px;
        background-color: #000;
        border-radius: 6px;
        height: 44px;
        line-height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        font-weight: 510;
        color: #fff;

        .sys-icon {
          width: 16px;
          height: 16px;
          margin-right: 6px;
        }
      }
      .download-btn-left {
        margin-right: 10px;
      }
    }

    .compare-wrapper {
      background-color: #333;
      padding: 30px 0;
      display: grid;
      width: 100%;
      margin-top: 50px;
      grid-template-columns: 1fr 70px 70px 70px;

      .logo-container {
        padding-bottom: 10px;
        display: flex;
        justify-content: center;

        .logo {
          width: 40px;
          height: 40px;

          &.timemark {
            background: url('./assets/logo.png') no-repeat;
            background-size: cover;
            position: relative;

            .like {
              width: 22px;
              height: 22px;
              position: absolute;
              top: -11px;
              right: -11px;
            }
          }
        }
      }

      .res-container {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .feature-name {
        padding: 14px 0;
        font-size: 14px;
        font-weight: 700;
        line-height: 17px;
        color: #fff;
        padding-left: 20px;
      }

      .check {
        width: 20px;
        height: 17px;
      }

      .cross {
        width: 12px;
        height: 12px;
      }

      .odd {
        background-color: #45464c;
      }
    }

    .link-wrapper {
      margin-top: 50px;

      .learning {
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
        color: #222;
        text-align: center;
      }

      .apps {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-column-gap: 20px;
        margin-top: 20px;

        .app-icon {
          width: 36px;
          height: 36px;
        }
      }
    }

    .download-btn-1 {
      background-color: #000;
      border-radius: 5px;
      margin-top: 70px;
      margin-bottom: 120px;
      height: 50px;
      line-height: 50px;
      display: flex;
      align-items: center;
      padding-left: 18px;
      padding-right: 27px;
      font-size: 16px;
      font-weight: 590;
      color: #fff;

      .sys-icon {
        width: 18px;
        height: 18px;

        &.android {
          margin-left: 4px;
          margin-right: 14px;
        }
      }
    }
  }
}
</style>
